import axios from 'axios';
import moment from 'moment';
import ContentLoader from 'react-content-loader'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import No_Data_Found_Logo from '../Assets/no-data-found.svg'

export const NO_DATA_FOUND = (message)=>{
  return (
    <div style={{alignContent:"center",alignItems:"center",textAlign:"center",fontFamily:"Jost"}}>
      <img src={No_Data_Found_Logo} alt="no-data-found" style={{ margin:"5rem auto 1rem auto",alignSelf:"center"}} /> 
      <p style={{color:"#676AC0",fontWeight:"600"}}>{message}</p>
    </div>
  )
};

export const SweetAlert = (config) =>{
  withReactContent(Swal).fire({
    title: `<p style="font-family:Jost; font-size:16px; font-weight:400px; line-height:27px; color:#888888";>${config.message}</p>`,
    icon: config.icon,
    confirmButtonText: config.confirm_btn_txt,
    cancelButtonText: config.cancel_btn_txt,
    showCancelButton: config.cancel_btn_show,
    didOpen: () => {},
  }).then((result) => {
      config.then_call_back(result);
  });
} 

export const MyLoader = () => <ContentLoader />

export const date_time_format = "DD MMM YYYY hh:mm A";
export const date_format = "DD MMM YYYY";

export const apiCall = (params) => {
  axios(params.config)
  .then(function (response) {
    params.successCallback(response);
  })
  .catch((error) => {
    params.errorCallback(error);
  });

}

export const number_to_string = (msg1) => {
  if(msg1 == "null" || msg1 == null){
      return '';
  }
  return msg1.toString();
}

 export const assign_empty = (msg1) => {
    if(msg1 == "null" || msg1 == null || msg1 == "0000-00-00"){
        return '';
    }

    return msg1.toString();

 }  

export const getData = (key) => {
  if(typeof this.state.formData[key] == "undefined") {
      return "";
  }
  return assign_empty(this.state.formData[key]);
}  

//format date and time function

export const format_date_str = (date) => {
  return moment(date).format(date_format);    
}

export const format_date_time_str = (date) => {
  return moment(date).format(date_time_format);    
}

export const format_day_str = (date) => {
  return moment(date).format("DD");    
}

export const format_month_str = (date) => {
  return moment(date).format("MMM");    
}

export const format_date_week_str = (date) => {
  return moment(date).format("dddd DD, MMM hh:mm A");    
}

export const format_time_str = (date) => {
  return moment(date).format("hh:mm A");    
}
