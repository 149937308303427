const HOST = 'https://webapp.smartbuildingmaintenance.com/';
const BASE_URL = HOST + 'api/user/';
const S3_BASE_URL = "https://facilitymanagement.s3.ap-south-1.amazonaws.com/";
const WEBSITE_BASE_URL = "https://hoabl.in/";
const API_BASE_URL = "https://fm-admin.hoabl.in/api/user/"

export const WEBSITE_URL = WEBSITE_BASE_URL;   //POST

export const LOGIN_URL = BASE_URL + 'user/login';   //POST
export const LOGOUT_URL = BASE_URL + 'user/logout';   //POST

// Property List
export const PROPERTY_DEFAULT_IMG_URL = S3_BASE_URL + "apartment-default.png"

// Society list
export const PROPERTIES_LIST_URLS = API_BASE_URL + 'society/user/all';
export const SOCIERTY_MAKE_ACTIVE = API_BASE_URL + 'society/make/active';
export const PROPERTIES_TOKEN = 'oUqSoSqoTzePULx83S9IzfaXH1T7UiUSzDcS5HIMqB75Kum2F4HPcQ7eYuYpdc17UpZILaGLS3AfDmttxqOYh6Zj5v6RdYAmFDYpO1hzWz9COs5L2WORaz09qDFn7abT';

// Visitors
export const VISTIOR_IMG_URL = S3_BASE_URL;   //POST
export const VISITORS_LIST_URL = API_BASE_URL + "visitor/lists";

// Gatepass
export const GATEPASS_IMG_URL = S3_BASE_URL + 'user/';   //POST
export const GATEPASS_LIST_URL = API_BASE_URL + "gate-pass/lists";
export const GATEPASS_REQUIRED_INFO_URL = API_BASE_URL + "gate-pass/required/info";
export const GATEPASS_SAVE_URL = API_BASE_URL + "gate-pass/save";
export const GATEPASS_EXPIRE_URL = API_BASE_URL + "gate-pass/cancel/save";

// Dailyhelpers
export const DAILY_HELPER_IMG_URL = S3_BASE_URL + 'user/';   //POST
export const DAILYHELPER_STAFF_ALL_URL = API_BASE_URL + "staff/all";
export const DAILYHELPER_STAFF_SAVE_URL = API_BASE_URL + "staff/save";
export const DAILYHELPER_STAFF_DETAILS_URL = API_BASE_URL + "staff/details";
export const DAILYHELPER_STAFF_DELETE_URL = API_BASE_URL + "staff/delete";

// Facilities
export const FACILITY_IMG_URL = S3_BASE_URL + 'property/';
export const FACILITY_DEFAULT_IMG_URL = S3_BASE_URL + "apartment-default.png"
export const FACILITY_LIST_URL = API_BASE_URL + "property/all";
export const AMENITY_BOOKINGS_URL = API_BASE_URL + "property/booking/all";
export const FACILITY_DETAILS_URL = API_BASE_URL + "property/booking/required/info";
export const AMENITY_BOOKINGS_SAVE_URL = API_BASE_URL + "property/booking/save";

// Complains
export const COMPLAINTS_LIST_URL = API_BASE_URL + 'complain/all';
export const COMPLAINTS_SAVE_URL = API_BASE_URL + 'complain/save';
export const COMPLAINTS_RESPONSE_LIST_URL = API_BASE_URL + "complain/messages";
export const COMPLAINTS_REQUIRED_INFO_URL = API_BASE_URL + "complain/required/info";
export const COMPLAINTS_RESPONSE_SAVE_URL = API_BASE_URL + "complain/message/save";

// Community
export const COMMUNITY_MEETINGS_LIST_URLS = API_BASE_URL + "meeting/all";
export const COMMUNITY_MEETING_REQUEST_URL = API_BASE_URL + "meeting/status/update";
export const COMMUNITY_POLL_LIST_URLS = API_BASE_URL + "poll/all";
export const COMMUNITY_EVENTS_LIST_URLS = API_BASE_URL + "event/all";
export const COMMUNITY_EVENTS_SAVE_URL = API_BASE_URL + "event/save";
export const COMMUNITY_EVENTS_DETAILS_URL = API_BASE_URL + "event/details";
export const COMMUNITY_ANNOUNCEMENT_LIST_URLS = API_BASE_URL + "announcements/all";
export const COMMUNITY_POLL_SAVE_URLS = API_BASE_URL + "poll/save";
export const COMMUNITY_FEEDBACK_SAVE_URL = API_BASE_URL + "feedback/save";
export const COMUUNITY_MODULE_WISE_NOTIFICATIONS_URL = API_BASE_URL + "notification/module";
export const COMMUNITY_LOST_AND_FOUND_LIST_URL = API_BASE_URL + "lost/found/all";
export const COMMUNITY_LOST_AND_FOUND_DETAILS_URL = API_BASE_URL + "lost/found/details";
export const COMMUNITY_LOST_AND_FOUND_IMG_URL = S3_BASE_URL + "lost_found/";

// Directory
export const DIRECTORY_VEHICLES_LIST_URLS = API_BASE_URL + "vehicle/lists";
export const DIRECTORY_GUARDS_LIST_URLS = API_BASE_URL + "gatekeeper/user/all";
export const DIRECTORY_HELPERS_LIST_URLS = API_BASE_URL + "vendor/all";
export const DIRECTORY_EMERGENCY_CONTACTS_LIST_URLS = API_BASE_URL + "number-directory/all";
export const DIRECTORY_RESIDENTS_LIST_URLS = API_BASE_URL + "user/lists";

// Accounts
export const ACCOUNTS_INCOME_EXPENDITURE_LIST_URL = API_BASE_URL + "reports/pl"
export const ACCOUNTS_REPORTS_BALANCESHEET = API_BASE_URL + "reports/balancesheet";

// Construction Guidelines
export const CONSTRUCTION_GUIDELINE_RULES_URL = API_BASE_URL + "rules/all";
export const CONSTRUCTION_REQUEST_CONTRACTOR_SAVE_URL = API_BASE_URL + "construction/guideline/request/contractor";
export const CONSTRUCTION_REQUEST_NOC_SAVE_URL = API_BASE_URL + "construction/guideline/request/noc";
export const CONSTRCUTION_CONTRACTOR_LIST_URL = API_BASE_URL + "construction/guideline/contractor/lists";
export const CONSTRUCTION_NOC_LIST_URL = API_BASE_URL + "construction/guideline/request/noc/lists";
export const CONSTRUCTION_REQUEST_CONSTRACTOR_LIST_URL = API_BASE_URL + "construction/guideline/request/contractor/lists";
export const CONSTRUCTION_PROJECT_UPDATE_STATUS_LIST_URL = API_BASE_URL + "construction/guideline/status/messages";

export const VISITOR_PDF_DOWNLOAD_URL = API_BASE_URL + 'visitor/download/pdf'; //  POST

export const NOTIFICATION_URL = API_BASE_URL + "notification/all"

export const DASHBOARD_STATS_URL = API_BASE_URL + "dashboard";

export const HOME_BANNER_URL = API_BASE_URL + "home/banner/all";
export const NOTIFICATION_COUNT_URL = API_BASE_URL + "notification/is_new/unread/count";

export const CONTENT_IMG_URL = S3_BASE_URL + "content/"

